@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Roboto:ital,wght@0,400;0,500;0,900;1,500;1,900&family=Poppins:wght@400;500;700;800&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

section {
  height: 100vh;
}

.roboto {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
}

.lobster {
  font-family: 'Lobster', cursive;
}

.poppins {
  font-family: 'Poppins', sans-serif;
}

.vh-half {
  height: 30vh;
}
